.searchat-bot-message-bubble {
    padding: 10px;
    background-color: #eee;
    border-radius: 5px;
    max-width: 70%;
    width: auto;
    animation: fadeInLeft 200ms ease-in-out;
    overflow-wrap: break-word;
  }


  .searchat-bot-message-bubble img {
   object-fit: cover;
 }

 @keyframes fadeInLeft {
   0% {
      opacity: 0;
      transform: translateX(-20px);
   }
   100% {
      opacity: 1;
      transform: translateX(0);
   }
} 

body{
   background: #d8d8d8;
}

.social-menu {
   padding: 3px;
   background-color: #f8f6dc;
   border-radius: 5px;
   max-width: 70%;
   width: auto;
   animation: fadeInLeft 200ms ease-in-out;
   overflow-wrap: break-word;
 }

.social-menu ul{
   position:relative; 
   left:10%;   
   padding: 2px;
   transform: translate(-10%, -5%);
   display: flex;
}

.social-menu ul li{
   list-style: none;
   margin: 0 15px;
}



.social-menu ul li .fa {
   font-size: 15px;
   line-height: 35px;
   transition: .3s;
   color: #445740;
}


.social-menu ul li .fab:hover{
   color: #fff;
}

.social-menu ul li a{
   position: relative;
   display: block;
   width: 28px;
   height: 28px;
   border-radius: 50%;
   background-color: #f4f1f1;
   text-align: center;
   transition: .6s;
   box-shadow: 0 5px 4px rgba(0,0,0,.5);
}

.social-menu ul li a:hover{
   transform: translate(0, -10%);
}

.social-menu ul li:nth-child(1) a:hover{
   background-color: #CEB987;
}
.social-menu ul li:nth-child(2) a:hover{
   background-color: #CEB987;
}
.social-menu ul li:nth-child(3) a:hover{
   background-color: #CEB987;
}
.social-menu ul li:nth-child(4) a:hover{
   background-color: #CEB987;
}

/* .social-menu span:before {
	content: "Copied";
	position: absolute;
	top: -45px;
	right: 0px;
	background: #5c81dc;
	padding: 8px 10px;
	border-radius: 20px;
	font-size: 15px;
	display: none;
}
.social-menu span:after {
	content: "";
	position: absolute;
	top: -20px;
	right: 25px;
	width: 10px;
	height: 10px;
	background: #5c81dc;
	transform: rotate(45deg);
	display: none;
}
.social-menu.active span:before,
.social-menu.active span:after {
	display: block;
} */

.copiedtext {
   position: absolute;
   left: 0; top: 0; right: 0;
   text-align: center;
   opacity: 0;
   transform: translateY(-1em);
   color: #000;
   transition: all .500s;
 }
 .import-message {
   animation: mymove 3s infinite;
 }

 @keyframes mymove {
   0% {
     opacity: 0;
     transform: translateX(100%);
   }
   70% {
     opacity: 1;
     transform: translateX(0);
   }
   100% {
     opacity: 0;
   }
 }